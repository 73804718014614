/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* Last used search groupings use this one.*/
.infoCard {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}
.infoCardFill {
  height: 160px;
  width: 319px;
}
.adjustMenu {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}
.adjustMenuFill {
  height: 160px;
  width: 319px;
}
.sideMenu {
  margin-top: 15px;
  margin-left:10px;
  margin-right: 20px;
  width: 215px;
}
.infoCardList {
  display: table;
  margin: 0 auto;
}
/** Can be used to center any element */
.centerSpin {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}
/** color of summary in material tables, and the table withing FO transactions. */
.mat-footer-cell {
  background-color: #f3f3f3;
}
.greyTab {
  background-color: #f3f3f3;
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}
/* https://dkreider.medium.com/how-to-add-a-spinner-to-an-angular-material-button-the-simplest-way-ever-69e2f7005f29 */
@keyframes spinner {
  to {transform: rotate(360deg);}
}
ul {
  text-align: left;
  list-style-type: none;
}
li {
  font-size: 15px;
}
.infoCardContent {
  margin-top: 20px;
}
